import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import { CtaGetDemo } from "components/cta"

import {
  EquityHubHero,
  EquityHubStatement,
  EquityResources,
} from "src/sections/equity_hub"

const EquityHubPage = ({ data: { equity_hub } }) => {
  const meta = useMeta(equity_hub)

  const {
    equity_hub_hero,
    equity_hub_statement,
    equity_hub_resources,
    cta_get_demo,
  } = useContentfulSection(equity_hub.sections)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <EquityHubHero {...equity_hub_hero} />
        <EquityHubStatement {...equity_hub_statement} />
        <EquityResources {...equity_hub_resources} />

        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    equity_hub: contentfulPage(pageId: { eq: "equity_hub" }) {
      ...Page
    }
  }
`

export default EquityHubPage
