import React from "react"
import styled from "styled-components"
// import { device } from "utils"
import { Section, ValueProp } from "components/common"
import Masonry from "react-masonry-css"

import map from "lodash/map"
import { useCallback } from "react"
import { device } from "src/utils"

const EquityResources = ({ sectionId, valueProps }) => {
  const renderValueProps = useCallback(
    () =>
      map(valueProps, (vp) => (
        <ValueProp
          className="resourceCard"
          isCard
          key={vp.id}
          {...vp}
          media={{ desktop: vp.icon }}
        />
      )),
    [valueProps]
  )
  return (
    <StyledEquityResources id={sectionId}>
      <Section>
        {/* https://www.npmjs.com/package/react-masonry-css */}
        <Masonry
          className="resources-grid"
          columnClassName="resources_col"
          breakpointCols={{ default: 2, 1024: 1 }}
        >
          {renderValueProps()}
        </Masonry>
      </Section>
    </StyledEquityResources>
  )
}

const StyledEquityResources = styled.div`
  /* --section-padding-top: 0; */
  --section-padding-bottom: 0;
  --section-mobile-padding-bottom: 0;
  --section-max-width: 1080px;

  .resources-grid {
    display: flex;
    width: auto;

    @media ${device.laptop} {
      margin-left: -35px;
    }
    .resources_col {
      background-clip: padding-box;

      @media ${device.laptop} {
        padding-left: 35px; /* gutter size */
      }

      &:first-child {
        padding-left: 0px;
      }

      .resourceCard {
        width: 100%;
        --card-padding: var(--sp-32);

        margin-bottom: 24px;
        @media ${device.laptop} {
          margin-bottom: 35px;
        }

        .tgWrap {
          /* --tg-grid-gap: var(--sp-16); */
          p:nth-child(2) {
            /* margin: var(--sp-8) 0; */
            margin-bottom: var(--sp-8);
            font-weight: normal;
          }
        }

        p,
        a {
          font-size: 1rem;
          line-height: 24px;

          .bold {
            font-weight: 700;
          }
        }

        ul {
          list-style: initial;
          margin-left: 20px;

          a {
            @media ${device.laptop} {
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        @media ${device.laptop} {
          --card-padding: var(--sp-64);
        }
      }
    }
  }
`

export default EquityResources
