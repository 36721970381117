import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

const EquityHubHero = ({ title, subtitle, media }) => {
  return (
    <StyledEquityHubHero>
      <VerticalSection
        critical
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        media={media}
      />
    </StyledEquityHubHero>
  )
}

const StyledEquityHubHero = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);

  img {
    width: 100%;
  }
`

export default EquityHubHero
