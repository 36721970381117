import React from "react"
import styled from "styled-components"
// import { device } from "utils"
import { HSection } from "components/common"

const EquityHubStatement = ({ sectionId, title, subtitle, media }) => {
  return (
    <StyledEquityHubStatement>
      <HSection
        id={sectionId}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
      />
    </StyledEquityHubStatement>
  )
}

const StyledEquityHubStatement = styled.div`
  /* --section-padding-top: 0;
  --section-padding-bottom: 0; */

  --tg-max-width: 500px;
  --section-max-width: 1080px;
`

export default EquityHubStatement
